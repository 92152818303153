import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/alumni-workshops-website/alumni-workshops-website/src/components/workshop-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Introduction to TypeScript`}</h1>
    <h2>{`2022-05-28 10:00am`}</h2>
    <p><em parentName="p">{`Duration: 2 hours`}</em></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220528T170000Z%2F20220528T190000Z&details=In%20this%202-hour%20session%2C%20we%27ll%20get%20you%20started%20writing%20TypeScript%20code.%20You%20will%20learn%20the%20advantages%20of%20using%20static%20typing%20to%20shorten%20the%20development%20cycle%2C%20spotting%20errors%20earlier%20in%20the%20process.&location=http%3A%2F%2Flovelace-labs.adadev.org&text=Getting%20Started%20with%20Typescript%20with%20Chris%20McAnally"
        }}>{`Add To Google Calendar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=In%20this%202-hour%20session%2C%20we%27ll%20get%20you%20started%20writing%20TypeScript%20code.%20You%20will%20learn%20the%20advantages%20of%20using%20static%20typing%20to%20shorten%20the%20development%20cycle%2C%20spotting%20errors%20earlier%20in%20the%20process.&enddt=2022-05-28T19%3A00%3A00%2B00%3A00&location=http%3A%2F%2Flovelace-labs.adadev.org&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=2022-05-28T17%3A00%3A00%2B00%3A00&subject=Getting%20Started%20with%20Typescript%20with%20Chris%20McAnally"
        }}>{`Add to Microsoft Calendar`}</a></li>
    </ul>
    <p>{`This introductory workshop will get you started writing TypeScript code. You will see the advantages to using static typing to shorten the development cycle, spotting errors earlier in the process.`}</p>
    <p>{`We will:`}</p>
    <ul>
      <li parentName="ul">{`Walk through setting up the Typescript development environment with VS Code`}</li>
      <li parentName="ul">{`Examine the advantages of using static typing`}</li>
      <li parentName="ul">{`Use Typescript's classes`}</li>
      <li parentName="ul">{`Extend Typescript classes & interfaces`}</li>
      <li parentName="ul">{`Convert a sample Express project to Typescript`}</li>
      <li parentName="ul">{`Write an NPM Module with Typescript`}</li>
    </ul>
    <p>{`The workshop will be very much hands-on with students writing and editing code. The goal is to position students to be able to continue learning TypeScript independently.`}</p>
    <h2>{`Chris McAnally`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bc9c95d5096e46fda8291b11f6bf2797/f93b5/chris-thumbnail.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "102%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAEEAgMF/8QAFwEBAQEBAAAAAAAAAAAAAAAAAgABA//aAAwDAQACEAMQAAABxVtpXMCFyDrkCv/EABwQAAEFAAMAAAAAAAAAAAAAAAIAAQMREgQQE//aAAgBAQABBQKMg8uUIONugLISSaAmp6WW6//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAwEBPwGBj//EABYRAQEBAAAAAAAAAAAAAAAAABEBIP/aAAgBAgEBPwErj//EAB8QAAEEAAcAAAAAAAAAAAAAAAEAAhESAyAhMUFRof/aAAgBAQAGPwJsjnVWwxC3Q7si2xM+Zf/EAB0QAAMAAgIDAAAAAAAAAAAAAAABETGRIUFRYXH/2gAIAQEAAT8hmuLsEWwkm4omh2y2N4bS1PUMmXcBkGOu3sl52P4j/9oADAMBAAIAAwAAABCDEMH/xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAwEBPxCkf//EABcRAQEBAQAAAAAAAAAAAAAAAAEAERD/2gAIAQIBAT8QQHENsL//xAAeEAACAgEFAQAAAAAAAAAAAAABEQAhQTFRYZGxof/aAAgBAQABPxApNdnFB0NrEIiJA5Cu4An1Qhg4mlir3yITQKoDVLMW1Fd8wJsgQwjhAAFCGbXFdkW4c//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Chris McAnally thumbnail",
            "title": "Chris McAnally thumbnail",
            "src": "/static/bc9c95d5096e46fda8291b11f6bf2797/f93b5/chris-thumbnail.jpg",
            "srcSet": ["/static/bc9c95d5096e46fda8291b11f6bf2797/f93b5/chris-thumbnail.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Chris has served as an Ada instructor since 2016 with Cohort 6. He specializes in teaching CS Fundamentals, testing and interviewing strategies, but has worked with every part of the Ada curriculum. Outside of coding Chris enjoys hiking in the mountains around Seattle and growing food in his garden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      